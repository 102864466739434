import * as React from "react";
import { SVGProps } from "react";
export function TicTocIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 30c8.285 0 15-6.715 15-15 0-8.284-6.715-15-15-15C6.716 0 0 6.716 0 15c0 8.285 6.716 15 15 15Z"
        fill="#000"
      />
      <path
        d="M12.913 12.472v-.819a6.31 6.31 0 0 0-.857-.062 6.367 6.367 0 0 0-6.36 6.36 6.356 6.356 0 0 0 2.715 5.206 6.334 6.334 0 0 1-1.708-4.33c0-3.456 2.772-6.275 6.21-6.356v.001Z"
        fill="#25F4EE"
      />
      <path
        d="M13.063 21.732a2.905 2.905 0 0 0 2.898-2.795l.006-13.846h2.53a4.807 4.807 0 0 1-.082-.877H14.96l-.006 13.847a2.905 2.905 0 0 1-2.898 2.795 2.89 2.89 0 0 1-1.347-.334 2.901 2.901 0 0 0 2.354 1.211ZM23.222 9.79v-.77a4.78 4.78 0 0 1-2.618-.778 4.81 4.81 0 0 0 2.618 1.548Z"
        fill="#25F4EE"
      />
      <path
        d="M20.604 8.241a4.785 4.785 0 0 1-1.182-3.151h-.925a4.816 4.816 0 0 0 2.107 3.151ZM12.056 15.046a2.908 2.908 0 0 0-2.905 2.905c0 1.115.633 2.084 1.557 2.57a2.883 2.883 0 0 1-.55-1.693 2.908 2.908 0 0 1 2.905-2.905c.298 0 .585.05.857.134V12.53a6.31 6.31 0 0 0-.857-.063c-.05 0-.1.003-.15.004v2.71a2.854 2.854 0 0 0-.858-.135Z"
        fill="#FE2C55"
      />
      <path
        d="M23.222 9.79v2.686a8.22 8.22 0 0 1-4.807-1.546v7.022a6.367 6.367 0 0 1-6.36 6.36 6.319 6.319 0 0 1-3.643-1.154 6.343 6.343 0 0 0 4.651 2.03 6.367 6.367 0 0 0 6.36-6.36v-7.021a8.215 8.215 0 0 0 4.807 1.545V9.897a4.82 4.82 0 0 1-1.007-.107Z"
        fill="#FE2C55"
      />
      <path
        d="M18.415 17.95v-7.02a8.215 8.215 0 0 0 4.807 1.545V9.789a4.81 4.81 0 0 1-2.618-1.548 4.816 4.816 0 0 1-2.107-3.151h-2.53l-.006 13.847a2.905 2.905 0 0 1-2.898 2.794 2.9 2.9 0 0 1-2.355-1.21 2.905 2.905 0 0 1-1.557-2.57 2.908 2.908 0 0 1 2.905-2.906c.299 0 .585.05.857.134V12.47c-3.438.08-6.21 2.9-6.21 6.356 0 1.672.65 3.194 1.708 4.33a6.32 6.32 0 0 0 3.645 1.153 6.366 6.366 0 0 0 6.36-6.36v.002Z"
        fill="#fff"
      />
    </svg>
  );
}
